<template>
    <!-- 任务列表 -->
    <div class="task grey-bg" v-if="template_status">
        <!-- 任务头部开始 -->
        <div class="task-head">
          <div class="integral" @click="goIntegral()">积分规则
            <img src="~assets/images/public/task-right.png" class="integral-imgs" />
          </div>
            <img class="head-bg" src="~assets/images/task/task-bg.png"/>
            <div class="head-user-info">
                <div class="user-info-con">
                    <div class="user-head-info vertical-center">
                      <div class="head-info-con vertical-center">
                        <img class="head-img"
                             :src="userInfo.avatar && userInfo.avatar ? userInfo.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png' "/>
                        <div>
                          <div class="vertical-center">
                            <div class="info-nickname white-space">{{
                                userInfo.nickname ? userInfo.nickname : '酸谈社群'
                              }}
                            </div>
                            <div class="info-rade align-center">
                              <div>
                                Lv{{ userInfo.level }}
                              </div>
                            </div>
                          </div>
                          <div class="serial-number" v-if="userInfo && userInfo.number">编号:{{userInfo.number}}</div>
                        </div>

                      </div>


                        <div class="sign-now align-center center-text" :class="receptions && receptions.is_sign ? 'immediately-active' : ''" v-if="receptions != null" @click="goSign">
                            <div>{{receptions.is_sign ? '已签到' : '签到'}}</div>
                        </div>
                    </div>
                    <div v-if="current_name != 'limited'">
                      <div class="user-info-item space-between" v-if="!userInfo.is_fresher">
                        <text class="user-progress">周任务进度</text>
                        <text class="uesr-reset">每周{{ tasksData.day ? tasksData.day : '一' }} 00:00重置</text>
                      </div>
                      <div class="progress vertical-center" v-if="!userInfo.is_fresher">
                        <van-progress class="progress-item"
                                      :percentage="userInfo && userInfo.can_do ? parseInt(tasksData.rate ? (tasksData.rate * 100) : 0) : 100"
                                      stroke-width="8" :show-pivot="false"
                                      color="linear-gradient(0deg, #F28E26 0%, #FD644F 100%)" track-color="#E6E6E6"/>
                        <div class="progress-text">{{
                            userInfo && userInfo.can_do ? parseInt(tasksData.rate ? (tasksData.rate * 100) : 0) : 100
                          }}%
                        </div>
                      </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- 任务头部结束 -->

        <!-- 任务列表开始 -->
        <div class="task-all" :class="[userInfo.is_fresher || current_name == 'limited' ? 'task-new' :'']">
            <div class="task-novice" :class="[userInfo.is_fresher ? 'task-name' :'']">
                <van-tabs title-active-color="#FF6B29" title-inactive-color="#4D4D4D"
                          line-width="40" line-height="2"
                          class="custom-class" v-model:active="active" @click="onChange">
                    <div v-for="(item,index) in navBars" :key="index">
                        <van-tab :title="item.title" :name="item.name">
                            <div class="task-con">
                                <div class="task-item">
                                    <NoData v-if="tasks.length == 0" :bottomBg="bottomBg"></NoData>
                                    <task v-else :task="tasks" :package_id="package_id" :type="active"
                                          @changeReject="changeReject"></task>
                                </div>
                            </div>
                        </van-tab>
                    </div>
                </van-tabs>
            </div>
            <div></div>
        </div>
        <!-- 任务列表结束 -->

        <!-- 他们在做开始 -->
        <div class="other-doing" v-if="otherDoing.length > 0">
            <div class="doing-title">TA们在做</div>
            <div class="other-ding-con">
                <div class="other-ding-item vertical-center" v-for="item in otherDoing" :key="item.id">
                    <img class="other-ding-img" :src="item.avatar && item.avatar ? item.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png' "/>
                    <span class="ding-name white-space">{{ item.task_name }}</span>
                    <div class="rise-integral vertical-center">
                        <div class="integral-null">+{{ $handle.tranNumber(item.experience, 3) }}</div>
                        <img class="integral-img"
                             src="~assets/images/task/rise.png"/>
                    </div>
                </div>

            </div>
            <BottomTip v-if="otherDoing.length > 2"></BottomTip>
        </div>

        <!-- 他们在做结束 -->
        <!--每天的审核通过提示 经验值开始-->
        <van-overlay :show="day_reward_status" z-index="999">
            <div class="overlay-examine-con">
                <div class="overlay-examine-wiper">
                    <img class="overlay-examine-bg" src="~assets/images/user/overlay-sign-bg.png"/>
                    <div class="overlay-examine-info">
                        <div class="overlay-examine-tip">
                            <div class="overlay-examine-tip-con">
                                <div class="overlay-examine-tip-tit">审核通过，奖励已发放</div>
                            </div>
                            <div class="overlay-examine-list" :class="reward_num > 0 && experience_num > 0 ? '' : 'overlay-examine-l'">
                                <div class="overlay-examine-item" v-if="reward_num > 0">
                                    <div class="overlay-examine-tip-icon">积分</div>
                                    <div class="overlay-examine-tip-text">+{{ $handle.tranNumber(reward_num, 3) }}</div>
                                </div>
                                <div class="overlay-examine-item" v-if="experience_num > 0">
                                    <div class="overlay-examine-tip-icon">经验值</div>
                                    <div class="overlay-examine-tip-text">+{{
                                            $handle.tranNumber(experience_num, 3)
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="overlay-determine">
                                <img class="overlay-determine-bg" src="~assets/images/user/overlay-determine.png"/>
                                <div class="overlay-determine-text" @click="rewardClose">知道了</div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="overlay-reward-close" src="~assets/images/user/overlay-cancel-icon.png"
                     @click="rewardClose"/>
            </div>
        </van-overlay>
        <!--每天的审核通过提示 经验值结束-->
    </div>

</template>

<script>
import task from "components/task";
import NoData from "components/NoData";
import {reactive} from "vue"
import BottomTip from "components/BottomTip";

export default {
    name: "index",
    data() {
        return {
            otherDoing: [],  //Ta们在做
            tasks: [],       //任务列表
            tasksData: {},
            package_id: 0, //任务包id
            navBars: [],
            active: 'week',  //tab默认选中
            reward_status: false,
            day_reward_status: false,
            reward_text: '经验值',
            reward_type: 'experience',
            reward_num: 0,    // 积分奖励
            experience_num: 0,  // 经验奖励
            tasksInfo: null,
            experience_ids: [],  // 经验通知ids
            bottomBg: true,
            isShow: false,
            awardInfo: {},  // 奖励
            template_status: false,
            current_name: '', // 当前tab选中的
            receptions: null, // 签到信息
        }
    },
    created() {
      if(!this.$cache.getIsLogin()) {
        this.$store.commit('M_LOGIN_POP', true)
      }else {
        // 判断加入战队状态
        let status = this.userInfo && this.userInfo.status_team ? this.userInfo.status_team.operate_status : -1;
        let statusArr = [1,4,5,6]
        if(statusArr.indexOf(status) != -1){
          this.$router.replace({path: '/join'})
          return false
        }else if(this.userInfo && this.userInfo.status_team && this.userInfo.status_team.operate_status == 2){
          this.$router.replace({path: '/audit'})

          return false
        }

        this.template_status= true
        if (this.userInfo && this.userInfo.is_fresher == 1) {
          this.getTask('novice')
          this.navBars = [{title: "新手任务", name: "novice"}]
        } else {
          if (this.$route.query.type) {
            this.active = this.$route.query.type
            this.getTask(this.$route.query.type)
          } else {
            this.getTask('week')
          }
          this.navBars = [
            {title: "本周任务", name: "week"},
            {title: "今日任务", name: "today"},
            {title: "限时任务", name: "limited"},
          ]
        }

        this.getReceptions()
      }
    },
    mounted() {
        this.$nextTick(() => {
            this.$util.domMinHeight()
        })
    },
    methods: {
      //跳转
      goIntegral(){
        this.$router.push('/task/integral')
      },
        // 获取任务
        getTask(type) {
            let _this = this
            let url
            if (type === 'novice') {
                url = _this.$api.Tasks + '?getTIme=' + new Date().getTime()
            } else {
                url = _this.$api.Tasks + `?type=${type}` + '&getTIme=' + new Date().getTime();
            }
            if(type === 'limited') {
              url = _this.$api.Taskslimited
            }
            _this.$http.get(url, true).then(res => {
                _this.isShow = true
                if (res.data.success) {
                    _this.tasksInfo = res.data.data;
                    // 判断显示奖励的
                    _this.rewardFun();

                    if (res.data.data.length === 0) {
                        _this.tasks = res.data.data
                        _this.package_id = res.data.data
                        _this.getRelatives()
                    } else {
                        // _this.tasks = res.data.data.data
                        _this.package_id = res.data.data.package_id
                        _this.tasks = _this.$util.taskSort(res.data.data.data)
                        _this.getRelatives()
                    }
                    // _this.tasks = res.data.data.data
                    // _this.package_id = res.data.data.package_id
                    _this.tasksData = res.data.data
                    if (_this.tasksData.length === 0) {
                        return
                    }
                    switch (_this.tasksData.day) {
                        case 1:
                            _this.tasksData.day = '一'
                            break;
                        case 2:
                            _this.tasksData.day = '二'
                            break;
                        case 3:
                            _this.tasksData.day = '三'
                            break;
                        case 4:
                            _this.tasksData.day = '四'
                            break;
                        case 5:
                            _this.tasksData.day = '五'
                            break;
                        case 6:
                            _this.tasksData.day = '六'
                            break;
                        case 0:
                            _this.tasksData.day = '日'
                            break;
                        default:
                            _this.tasksData.day = '一'
                    }
                    reactive(this.tasksData)
                }
            })
        },
        // TA们在做
        getRelatives() {
            let _this = this
            let url = _this.$api.Relatives + '?package_id=' + _this.package_id
            this.$http.get(url, true).then(res => {
                if (res.data.success) {
                    _this.otherDoing = res.data.data
                }
            })
        },
        changeReject() {
            this.isReject = true
        },
        //点击签到
        goSign() {
            this.$router.push('/task/sign')
        },
        //点击任务分类
        onChange(name) {
            this.tasks = []
            this.current_name= name
            this.getTask(name)
        },
        // 关闭记录
        rewardClose() {
            this.day_reward_status = false;
            if (this.reward_num > 0) {
                this.rewardPop();
                this.reward_num= 0
            }
            if (this.experience_num > 0) {
                this.experiencePop();
                this.experience_num= 0
            }
        },
        // 判断显示奖励的
        rewardFun() {
            let _this = this;
            let url = this.$api.UserEject;
            this.$http.get(url, true).then((res) => {
                if (res.data.success) {
                    _this.awardInfo = res.data.data;
                    if (_this.awardInfo.is_eject == 'Y' && ( parseInt( _this.awardInfo.all_credit ) > 0)) {
                        _this.reward_num = parseInt(_this.awardInfo.all_credit);
                        if(this.reward_num > 0){
                            this.day_reward_status = true
                        }
                    }
                }
            })
            let experience_num = 0;
            let experience_ids = []
            if(this.tasksInfo.data && this.tasksInfo.data.length > 0){
                if (this.tasksInfo.data.length) {
                    let tasksArr = this.tasksInfo.data
                    for (let i = 0; i < tasksArr.length; i++) {
                        if (tasksArr[i].status == 1 && tasksArr[i].is_notice == 'N') {
                            experience_num += parseFloat(tasksArr[i].experience);
                            experience_ids.push(tasksArr[i].id)
                        }
                    }
                    this.experience_num = experience_num;
                    this.experience_ids = experience_ids
                }
                if (this.experience_num > 0) {
                    this.day_reward_status = true
                }
            }
        },
        // 经验值弹框标记
        experiencePop() {
            let data = {
                ids: this.experience_ids.toString(),
                package_id: this.tasksInfo.package_id ? this.tasksInfo.package_id : 0,
                type: 0
            }
            this.$http.post(this.$api.Tasks, data, true).then(res => {
                if (!res.data.success) {
                    this.$toast(res.data.message)
                }
            })

        },
        // 积分弹框标记
        rewardPop() {
            let url = this.$api.UserEject;
            let data = {
              package_id: this.awardInfo.package_id ? this.awardInfo.package_id: 0,
            }
            this.$http.post(url,data,true).then(res => {
              if(!res.data.success){
                this.$toast(res.data.message)
              }
            })
        },
        //获取用户签到信息
        getReceptions(){
          let _this = this
          let url = _this.$api.Receptions + '?getTime=' + new Date().getTime()
          _this.$http.get(url,true).then(res => {
            if (res.data.success) {
              _this.receptions = res.data.data
            }
          })
        }
    },
    watch: {
        userInfo(val, oldVal) {
            if (val && val.is_fresher == 1) {
                this.getTask('novice')
                this.navBars = [{title: "新手任务", name: "novice"}]
            } else {
                if (this.$route.query.type) {
                    this.active = this.$route.query.type
                    this.getTask(this.$route.query.type)
                } else {
                    this.getTask('week')
                }
                this.navBars = [
                    {title: "本周任务", name: "week"},
                    {title: "今日任务", name: "today"},
                    {title: "限时任务", name: "limited"}
                ]
            }
            return val;
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo;
        }
    },
    components: {
        task,
        NoData,
        BottomTip
    }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/task/index.scss";
.immediately-active{
  opacity: 0.5;
}
.integral{
  position: absolute;
  top: 35/$r;
  right: 30/$r;
  font-size: 24/$r;
  font-weight: 400;
  color: #FFFFFF;
  width: 140/$r;
  height: 46/$r;
  border: 1/$r solid #FFFFFF;
  opacity: 0.6;
  border-radius: 23/$r;
  line-height: 46/$r;
  text-align: center;
}
.integral-imgs{
  width: 12/$r;
  height: 22/$r;
}
</style>
